<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" />
    <title>{{ title }}</title>
    <div>IP: {{ u }}</div>
    <br />
    <router-link to="/about">about</router-link>
  </div>
</template>


<script>
import axios from "axios";
import qs from "qs";

export default {
  data() {
    return {
      title: "首页",
      u: null,
    };
  },
  created() {
    this.myip();
  },
  methods: {
    myip() {
      axios.get("https://1.1.1.1/cdn-cgi/trace/").then((res) => {
        this.u = qs.parse(res.data.split("\n").join("&")).ip;
      });
    },
  },
};
</script>
